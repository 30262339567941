.window {
    border: 2px solid #000;
    background-color: #c3c3c3;
    font-family: 'MS Sans Serif';
    font-size: 14px;
    position: fixed;
}

.window.default-window {
    width: 300px;
}

.window.doodle-window {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Ensure height is also handled */
}

.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000080;
    color: white;
    padding: 2px 5px;
    position: relative;
    height: 20px;
}

.title-bar-text {
    flex-grow: 1;
}

.title-bar-controls {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.title-bar-controls button {
    background: #c3c3c3;
    border: 1px solid #000;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.window-body {
    padding: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-window {
    position: relative;
}

.close-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-button {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
}

.custom-rnd {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.custom-rnd>.react-draggable {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}