.folder-view {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
    -webkit-overflow-scrolling: touch;
    /* Ensure smooth scrolling on mobile */
}

.folder-view::-webkit-scrollbar {
    width: 10px;
}

.folder-view::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.folder-view::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    cursor: pointer;
    padding: 5px;
    /* Ensure touch-friendly area */
}

.folder img,
.folder .file-icon {
    width: 48px;
    height: 48px;
    pointer-events: none;
    /* Ensure images do not intercept clicks */
}

.folder p {
    margin-top: 5px;
    font-family: 'MS Sans Serif', sans-serif;
    font-size: 12px;
    color: #000;
    text-align: center;
    pointer-events: none;
    /* Ensure text does not intercept clicks */
}

.back-button {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    margin-bottom: 10px;
}